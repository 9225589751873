import { Link } from "react-router-dom"


export const FlyerProd = ({ubi, nombre, id, bodega}) => {

    return (
        <>
            <div className="content-flyerBox">
                <p className="content-flyerText">Elegí el vino destacado de <em>{bodega}</em></p>
                <div>
                    <Link to={`/detalle/${id}`}>
                        <button className="card-btn">COMPRAR</button>
                    </Link>
                </div>
            </div>
            <div className="content-flyerBox">
                <img className="content-flyerImg" src={ubi} alt={nombre} />
            </div>
        </>
        
    )
}