import { useContext } from 'react';
import { FaTrashAlt } from 'react-icons/fa'
import { CartContext } from '../CartContext/CartContext';
import { Navigate } from 'react-router-dom';

export const Cart = ( { id, nombre, precioActualizado, cantidad, foto } ) => {

    const {eliminarItem, link} = useContext(CartContext)

    if (link) {
        return <Navigate to="/checkout"/>
    }

    return (
        <div className='cart-items'>
            <div className='cart-boxImg'>
                <img className='cart-img' src={`/${foto}`} alt={nombre}/>
            </div>
            <p className='cart-desc'><strong>Producto: </strong>{nombre}</p>
            <p className='cart-desc'><strong>Precio:</strong> AR$ {(precioActualizado).toFixed(2)} c/u</p>
            <p className='cart-desc'><strong>Cantidad: </strong>{cantidad} u</p>
            <FaTrashAlt className='cart-trash' onClick={() => eliminarItem(id)}/>
        </div>
    );
}