import { doc, getDoc } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useCotDolar } from '../Api/useCotDolar'
import { Caracteristicas } from '../Caracteristicas/Caracteristicas'
import { Cargando } from '../Cargando/Cargando'
import { baseDeDatos, storage } from '../Firebase/Config'
import { ItemDest } from '../ItemDest/ItemDest'
import { ItemDetail } from '../ItemDetail/ItemDetail'
import { getDownloadURL, ref } from 'firebase/storage'

export const ItemDetailContainer = () => {

    const [loading, setLoading] = useState(false)
    const [item, setItem] = useState(null)
    const [imagen, setImagen] = useState(null)
    const {dolarOficial} = useCotDolar(0)
    const { itemId } = useParams()

    useEffect(() => {

        window.scrollTo({top: 0, left: 0, behavior: 'auto'});

        // const productos = ["0yN2e0IK53xyM30H7qN3", "2rkgaAjqOULkcK3lsMpk", "BT82j07o4Xx4mEmluyDW", "HgbrIcAvSVctwedpr9Bq", "bWrpz834Y0KStcDMJ5Rt", "kIbZ36TDjmMkJjrWSVWD", "rc0y0Ujd9qBTpxcPjBtd", "sOkJFmscjmMELdWOUs5Y", "sd5Xl6bu3eSIqMJLE47B", "wKZLQhj490qsRXirIhVi"]

        // const verificacion = productos.some((el) => el === itemId)

        // console.log(verificacion);

        document.title = "Detalle de Producto - JB Premium - Vinos Españoles - Distribuidor Oficial"

        setLoading(true)

        const itemBaseDeDatos = doc(baseDeDatos, "productos", itemId)

        getDoc(itemBaseDeDatos)
            .then((item) => {
                setItem({ id: item.id, precioDolar: dolarOficial, ...item.data() })

                const storageRef = ref(storage, `${item.data().dato}.png`)
                getDownloadURL(storageRef)
                    .then((url) => {
                        setImagen(url)
                    })
            })
            .finally(()=> {
                setLoading(false)
            })
    }, [itemId, dolarOficial])

    //CONDICIONAMIENTO PARA QUE SE VEA, SOLO EN LOS VINOS, LA OPCION PARA AGREGAR CUALQUIER CAJA AL CARRITO

    if (itemId === "0105VjaVJHmC1SV9BXLi") {
        return (
            <>
                <main className="main">
                    {loading ? <Cargando/> : <ItemDetail {...item} imagen={imagen}/>}
                </main>
            </>
            
        )
    } else if (itemId === "01160yB5ulx8pSNyKXcp") {
        return (
            <>
                <main className="main">
                    {loading ? <Cargando/> : <ItemDetail {...item} imagen={imagen}/>}
                </main>
            </>
            
        )
    } else {
        return (
            <>
                <main className="main">
                    {loading ? <Cargando/> : <ItemDetail {...item} imagen={imagen}/>}
                    
                </main>
                <section className='main dest'>
                    <p className='dest-title'>PODES COMBINAR ESTE VINO CON NUESTRAS CAJAS</p>
                    <div className='dest-container'>
                        <ItemDest/>
                    </div>
                </section>
                <Caracteristicas {...item}/>
            </>
            
        )
    }
}
