import React, { useState } from 'react'

export const Mayor = () => {

    const [mayor, setMayor] = useState(false)
    const [advice, setAdvice] = useState(null)

    const mayoriaEdad = () => {
        setMayor(true)
        localStorage.setItem("mayorDeEdad", true)
    }
    
    const minoriaEdad = () => {
        setAdvice("Debes ser mayor de edad para acceder a la web.")
    }

    if(localStorage.getItem("mayorDeEdad") === "true") {
        return null
    } else if (mayor === false) {
        return (
            <>
                <div className="consulta">
                    <div className='consulta-box'>
                        <img className="consulta-img" src="/img/logo.png" alt="logo"/>
                        <p className='consulta-title'>VINOS ESPAÑOLES</p>
                        <div className='consulta-boxText'>
                            <p className="consulta-text">¿Eres mayor de edad?</p>
                            <p className="consulta-desc">Verifica que tenés 18 años o más para ingresar a este sitio</p>
                            <p className="consulta-warning">{advice}</p>
                            <div className='consulta-boxBtns'>
                                <button onClick={mayoriaEdad} className='card-btn'>SI</button>
                                <button onClick={minoriaEdad} className='card-btn'>NO</button>
                            </div>
                        </div>
                            <p className='consulta-advice'>Beber con moderación. Prohibida su venta a menores de 18 años.</p>
                    </div>
                </div>
            </>
        )
    } else {
        return null
    }
}