import { useContext, useState } from 'react'
import {Link} from 'react-router-dom'
import { AddWidget } from '../AddWidget/AddWidget'
import { AdviceWidget } from '../AdviceWidget/AdviceWidget'
import { Aside } from '../Aside/Aside'
import { CartContext } from '../CartContext/CartContext'
import { CartWidget } from '../CartWidget/CartWidget'
import { Menu } from '../Menu/Menu'

export const NavBar = () => {

    const {prodAdd, advice, link} = useContext(CartContext)
    const [click, setClick] = useState(false)

    const handleClick = () => {
        setClick(!click)
    }

    return (
        <>
            <header className='nav'>
                <div className='nav-bandera'>
                    <div className='nav-banderaContent'>
                        <div className='nav-lineaRoja'></div>
                        <div className='nav-lineaAmarilla'></div>
                        <div className='nav-lineaRoja'></div>
                    </div>
                </div>
                <div className='nav-content'>
                    <div className='nav-logo'>
                        <Link to="/" className={link ? "disabled" : null}>
                            <img className='nav-img' src="/img/logo.png" alt="logo"/>
                        </Link>
                        <p className='nav-text'>VINOS ESPAÑOLES</p>
                    </div>
                    <nav className='nav-enlace'>
                        <Link to="/" className={link ? "nav-item disabled" : "nav-item"}>Inicio</Link>
                        <Link to="/nosotros" className={link ? "nav-item disabled" : "nav-item"}>Nosotros</Link>
                        <Link to="/productos" className={link ? "nav-item disabled" : "nav-item"}>Productos</Link>
                        <Link to="/cart" className={link ? "nav-item nav-flex disabled" : "nav-item nav-flex"}>Mi Carrito<CartWidget/></Link>
                    </nav>
                    <div className='nav-burger'>
                        <Menu click={click} handleClick={handleClick}/>
                        <CartWidget/>
                    </div>
                </div>
                <div className='nav-notif'>
                    {prodAdd
                    ? <AddWidget/>
                    : null}
                    {advice
                    ? <AdviceWidget/>
                    : null}
                    {click
                    ? <Aside handleClick={handleClick}/>
                    : null}
                </div>
            </header>
        </>
        
    )
}
