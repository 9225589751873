import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { Sticky } from '../Sticky/Sticky'

export const DetailVenta = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        document.title = "Detalle de Ventas - JB Premium - Vinos Españoles - Distribuidor Oficial"
    }, [])

    return (
        <>
            <main className="main">
                <h3 className='main-title'>VENTA MINORISTA, MAYORISTA Y COMERCIAL</h3>
                <hr/>
                <div className="detalle-venta">
                    <div className="detalle-ventaBox">
                        <img className="detalle-ventaImg" src="/img/CEE483F6-59AA-4520-BE86-CA9B4C4CFB0C.jpg" alt="foto"/>
                    </div>
                    <div className="detalle-ventaBox">
                        <p className='detalle-ventaTitle'>COMPRA MINORISTA</p>
                        <p className='detalle-ventaDesc'>A traves de nuestra pagina podes adquirir nuestros productos. Clickeando en "VER MAS" en cada item, podras acceder a todas su caracteristicas, agregar al carrito y finalizar la compra. Una vez tengas tu N° de pedido, JB Premium se contactará al numero que registraste para coordinar el envío tal como explicamos en <Link className="link-detail" to="/detalle-envio">detalle de envíos<BsFillQuestionCircleFill className='icon-question'/></Link>.</p>
                        <p className='detalle-ventaTitle'>COMPRA MAYORISTA</p>
                        <p className='detalle-ventaDesc'>Para compras mayorista o Comercialas, ofrecemos precios diferenciales, promociones que podran acceder contactandose con nuestro equipo de Ventas, clickeando el boton de whatsapp dentro de esta pagina.</p>
                    </div>
                </div>
                <div className='detalle-ventaBtn'>
                    <Link to="/" className="card-link">
                    <button className="card-btn">VOLVER</button>
                    </Link>
                </div>
            </main>
            <Sticky/>
        </>
    )
}