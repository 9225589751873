import { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { Cart } from "../Cart/Cart";
import { CartContext } from "../CartContext/CartContext";
import { ItemDest } from "../ItemDest/ItemDest";
import { Sticky } from "../Sticky/Sticky";


export const CartList = () => {

    const {cart, totalCompra, vaciarCarrito} = useContext(CartContext)

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        document.title = "Mi Carrito - JB Premium - Vinos Españoles - Distribuidor Oficial"
    }, [])

    return (
        <>
            <section className="main">
                <p className="main-title">MI CARRITO</p>
                <hr/>
                <div className="cart-content">
                    <div className="cart-content-item">
                        {
                            cart.length === 0
                            ?   <div className="cart-empty">  
                                    <p className="cart-emptyText">No hay productos en tu carrito</p>
                                    <Link to="/"><button className="card-btn card-size">VOLVER AL INICIO</button></Link>
                                </div>
                            : cart.map((prod) => <Cart key={prod.id} {...prod}/>) 
                        }
                    </div>
                    <div className="cart-content-total">
                        <em className="cart-totalTitle">Resumen de mi compra:</em>
                        <div className="cart-total">
                            <p className="cart-totalPrice">TOTAL: ${totalCompra().toLocaleString("es-AR")}</p>
                        </div>
                        <div className="cart-total-btns">
                            <button className="card-btn card-size" onClick={vaciarCarrito}>VACIAR CARRITO</button>
                            <Link to="/checkout" className="card-btn card-success card-size">FINALIZAR COMPRA</Link>
                        </div>
                    </div>
                </div>
            </section>
            {cart.length === 0 || cart.some((el) => el.id === "0105VjaVJHmC1SV9BXLi") || cart.some((el) => el.id === "01160yB5ulx8pSNyKXcp")
            ? null
            :   <section className="main dest">
                    <p className="dest-title">NO TE OLVIDES DE AGREGAR TU CAJA PARA LOS VINOS!</p>
                    <div className="dest-container">
                        <ItemDest/>
                    </div>
                </section>
            }
            
            <Sticky/>
        </>
    );
}

