import { useContext } from "react";
import { AiFillCloseCircle } from "react-icons/ai";
import { CartContext } from "../CartContext/CartContext";

export const Modal = ({nombre, foto}) => {

    const {handleOcultarImg} = useContext(CartContext)

    return (
        <div className="modal">
            <div className="modal-container">
                <div className="modal-containerIcon">
                    <AiFillCloseCircle onClick={handleOcultarImg} className="modal-icon"/>
                </div>
                <div className="modal-containerImg">
                    <img className="modal-img" src={`/${foto}`} alt={nombre}/>
                </div>
            </div>
        </div>
    )
}