import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { CartContext } from '../CartContext/CartContext'

export const Aside = ({handleClick}) => {

    const {link} = useContext(CartContext)

    return (
        <nav className='aside'>
            <Link onClick={handleClick} to="/" className={link ? "disabled nav-item aside-item" : "nav-item aside-item"}>Inicio</Link>
            <Link onClick={handleClick} to="/nosotros" className={link ? "disabled nav-item aside-item" : "nav-item aside-item"}>Nosotros</Link>
            <Link onClick={handleClick} to="/productos" className={link ? "disabled nav-item aside-item" : "nav-item aside-item"}>Productos</Link>
            <Link onClick={handleClick} to="/cart" className={link ? "disabled nav-item aside-item" : "nav-item aside-item"}>Mi Carrito</Link>
        </nav>
    )
}
