

export const Cargando = () => {


    return (
        <div className="cargando">
            <h4 className="cargando-text">CARGANDO</h4>
            <img className="cargando-img" src="/img/logo.png" alt="logo"/>
            <div className="cargando-spinner"></div>
        </div>
    )
}