import { Link } from "react-router-dom"
import ReactWhatsapp from 'react-whatsapp';
import { BsFillQuestionCircleFill, BsFacebook, BsInstagram } from "react-icons/bs";
import { useContext } from "react";
import { CartContext } from "../CartContext/CartContext";


export const Footer = () => {

    const {link} = useContext(CartContext)

    return (
        <footer className="footer">
            <div className="footer-row">
                <div className="footer-col footer-col-width">
                    <em className="footer-title"><Link className={link ? "link-detail footer-title disabled" : "link-detail footer-title"} to="/detalle-venta">Venta Minorista, Mayorista y Comercial<BsFillQuestionCircleFill className="icon-question"/></Link></em>
                    <em className="footer-title">Atención al Cliente</em>
                    <ul className="footer-ul">
                        
                        <li className="footer-item">De Lunes a Sabado de 10:00 a 18:00 Horas</li>
                        <li className="footer-item">
                            <ReactWhatsapp 
                                className="footer-link"
                                number="+5491166670119" 
                                message="Hola equipo de JB Premium, quisiera consultarte por"
                                >
                                Click aqui para nuestro chat
                            </ReactWhatsapp>
                        </li>
                        <li className="footer-item"><strong>Buenos Aires - Argentina</strong></li>
                        <li className="footer-item">contacto@jbpremium.com</li>
                    </ul>                
                </div>
                <div className="footer-col">
                    <em className="footer-title">Nuestras Secciones</em>
                    <ul className="footer-ul">
                        <li className="footer-item">
                            <Link className={link ? "footer-nav disabled" : "footer-nav"} to="/">
                                Inicio
                            </Link>
                        </li>
                        <li className="footer-item">
                            <Link className={link ? "footer-nav disabled" : "footer-nav"} to="/nosotros">
                                Nosotros
                            </Link>
                        </li>
                        <li className="footer-item">
                            <Link className={link ? "footer-nav disabled" : "footer-nav"} to="/productos">
                                Productos
                            </Link>
                        </li>
                        <li className="footer-item">
                            <Link className={link ? "footer-nav disabled" : "footer-nav"} to="/cart">
                                Mi Carrito
                            </Link>
                        </li>
                        <li className="footer-item">
                            <Link className={link ? "footer-nav disabled" : "footer-nav"} to="/detalle-envio">
                                Envíos
                            </Link>
                        </li>
                        <li className="footer-item">
                            <Link className={link ? "footer-nav disabled" : "footer-nav"} to="/preguntas-frecuentes">
                                Preguntas Frecuentes
                            </Link>
                        </li>
                    </ul>
                    <em className="footer-title">Nuestras Redes</em>
                    <ul className="footer-rowIcons">
                        <li className="footer-item">
                            <div className="footer-boxIcons">
                                <a className="footer-nav" target="_blank" rel="noreferrer" href="https://www.facebook.com/">
                                    <div className="footer-contentRedes">
                                        <BsFacebook className="footer-redes"/>
                                    </div>
                                </a>
                            </div>
                        </li>
                        <li className="footer-item">
                            <div className="footer-boxIcons">
                                <a className="footer-nav" target="_blank" rel="noreferrer" href="https://www.instagram.com/vinosespanolesjb/">
                                    <div className="footer-contentRedes">
                                        <BsInstagram className="footer-redes"/>
                                    </div>
                                </a>
                            </div>
                        </li>
                    </ul>
                </div>
                <div className="footer-col">
                    <div className="footer-boxImg">
                        <img className="footer-img" src="/img/logo.png" alt="logo"/>
                    </div>
                    <p className="footer-text">VINOS ESPAÑOLES</p>
                </div>
            </div>
        </footer>
    )
}
