import "./style/style.scss"
import { CartProvider } from "./components/CartContext/CartContext";
import { BrowserRouter, Routes, Route} from 'react-router-dom'
import { NavBar } from "./components/NavBar/NavBar.js";
import { ItemListContainer } from "./components/ItemListContainer/ItemListContainer";
import { ContentContainer } from "./components/ContentContainer/ContentContainer";
import { ItemDetailContainer } from "./components/ItemDetailContainer/ItemDetailContainer";
import { CartList } from "./components/CartList/CartList";
import { Checkout } from "./components/Checkout/Checkout";
import { Footer } from "./components/Footer/Footer";
import { Nosotros } from "./components/Nosotros/Nosotros";
import { DetailEnvio } from "./components/DetailEnvio/DetailEnvio";
import { DetailVenta } from "./components/DetailVenta/DetailVenta";
import { NotFound } from "./components/NotFound/NotFound";
import { Mayor } from "./components/Mayor/Mayor";
import { PaySuccess } from "./components/Pay/PaySuccess";
import { PayFailure } from "./components/Pay/PayFailure";
import { PayPending } from "./components/Pay/PayPending";
import { Faq } from "./components/Faq/Faq.js";

function App() {


    return (
      <CartProvider>
        <BrowserRouter>
          <Mayor/>
          <NavBar/>
          <Routes>
            <Route path="/" element={ <ContentContainer/> }/>
            <Route path="/productos" element={ <ItemListContainer/> }/>
            <Route path="/nosotros" element={ <Nosotros/> }/>
            <Route path="/detalle/:itemId" element={ <ItemDetailContainer/> }/>
            <Route path='/cart' element={ <CartList/> }/>
            <Route path='/checkout' element={ <Checkout/> }/>
            <Route path='/detalle-envio' element={ <DetailEnvio/> }/>
            <Route path='/detalle-venta' element={ <DetailVenta/> }/>
            <Route path='/pago-realizado' element={ <PaySuccess/> }/>
            <Route path='/pago-rechazado' element={ <PayFailure/> }/>
            <Route path='/pago-pendiente' element={ <PayPending/> }/>
            <Route path='/preguntas-frecuentes' element={ <Faq/> }/>
            <Route path='*' element={ <NotFound/> }/>
          </Routes>
          <Footer/>
        </BrowserRouter>
      </CartProvider>
  );
}

export default App;
