import { Link } from "react-router-dom"
import img1 from "../../images/pexels-david-1277181.jpg"
import { useEffect, useState } from "react";

export const PayFailure = () => {

    const [status, setStatus] = useState("")

    useEffect(() => {
        
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        document.title = "Pago Rechazado - JB Premium - Vinos Españoles - Distribuidor Oficial"

         // Obtén la URL actual
        const currentUrl = window.location.href;

        // Parsea los parámetros de la URL
        const urlParams = new URLSearchParams(currentUrl);

        // Obtiene el valor de collection_status
        const collectionStatus = urlParams.get('collection_status');

        setStatus(collectionStatus)

    }, [status])

    return (
        <main>
            <div className="pay-expand-success">
                <img className='content-flyerImage' alt="notFound" src={img1}/>
                <div className="pay-content failure-color">
                {status === "null"
                ?
                    <>                    
                        <p className="pay-title">UPS! SALISTE DE LA PLATAFORMA DE PAGO</p>
                        <div className="pay-box">
                            <p className="pay-desc">Saliste de la sección de pagos de JB Premium sin finalizar la transacción.</p>
                            <p className="pay-desc pay-msj">Pero no te preocupes! Vuelve a elegir tus productos y finaliza tu compra.</p>
                            <Link to="/productos"><button className="card-btn">VOLVER</button></Link>
                        </div>
                    </>
                :
                    <>
                        <p className="pay-title">TU PAGO A SIDO RECHAZADO</p>
                        <div className="pay-box">
                            <p className="pay-desc">Tuvimos un problema en recibir tu pago. Pero no te preocupes!</p>
                            <p className="pay-desc pay-msj">Podes volver a seleccionar tus productos e intentar con otro medio de pago.</p>
                            <p className="pay-desc">En breve te llegará un mail con el motivo del rechazo de tu pago. Recuerda revisar tu SPAM.</p>
                            <Link to="/"><button className="card-btn">VOLVER</button></Link>
                        </div>
                    </>
                }
                </div>
            </div>
        </main>
    )
}
