import React from 'react'
import { AiFillExclamationCircle } from "react-icons/ai"

export const AdviceWidget = () => {
    return (
        <div className='content-advice'>
            <AiFillExclamationCircle className='content-adviceIcon'/>
            <p className='content-adviceText'>Revisa los campos a completar</p>
        </div>
    )
}
