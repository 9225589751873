// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import {getAuth} from "firebase/auth"
import { getStorage } from "firebase/storage"
//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDXgKZArrNClZtThjYgqsmDJ3czutQkNBY",
  authDomain: "jb-premium.firebaseapp.com",
  projectId: "jb-premium",
  storageBucket: "jb-premium.appspot.com",
  messagingSenderId: "643042258540",
  appId: "1:643042258540:web:d3be02655ff52cb2fab249",
  measurementId: "G-S599SSNMQ4"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);

export const baseDeDatos = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)