import {useState, useEffect} from 'react';
import { Cargando } from '../Cargando/Cargando';
import { baseDeDatos, storage } from '../Firebase/Config';
import { collection, getDocs} from 'firebase/firestore'
import { ContentList } from '../ContentList/ContentList';
import { Slider } from '../Slider/Slider';
import { useCotDolar } from '../Api/useCotDolar';
import { FlyerEnvio } from '../Flyer/FlyerEnvio';
import { Sticky } from '../Sticky/Sticky';
import { getDownloadURL, listAll, ref } from 'firebase/storage';

export const ContentContainer = () => {

    const [productos, setProductos] = useState([])
    const [imagenes, setImagenes] = useState([])
    const [loading, setLoading] = useState(false)
    const {dolarOficial} = useCotDolar(0)

    useEffect (() => {

        document.title = "Inicio - JB Premium - Vinos Españoles - Distribuidor Oficial"
        setLoading(true)

        const productosBaseDeDatos = collection(baseDeDatos, 'productos')

        getDocs(productosBaseDeDatos)
            .then((resp) => {
                setProductos( resp.docs.map( (item) => {
                    return {
                        id: item.id,
                        precioDolar: dolarOficial,
                        ...item.data()
                    }
                }))
                const storageRef = ref(storage, '/');
                listAll(storageRef)
                    .then(async (result) => {
                        const promises = result.items.map(async (item) => {
                            const ubi = await getDownloadURL(item);
                            return { ubi, nam: item.name };
                        });
                        const urls = await Promise.all(promises);
                        setImagenes(urls);
                    })
                    .catch((error) => {
                        console.log("Error porque...", error);
                    });
            })
            .finally(() => {
                setLoading(false)
            })
    }, [dolarOficial])

    return (
        <>
            <Slider/>
            <main>

                {loading ? 
                <Cargando/> : 
                <ContentList productos={productos} imagenes={imagenes}/>}

            </main>
            <FlyerEnvio/>
            <Sticky/>
        </>
    );
}