import { useContext } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai"
import { Link } from "react-router-dom";
import { CartContext } from "../CartContext/CartContext";

export const CartWidget = () => {

    const { sumarCantidades, cart } = useContext(CartContext)

    return(
        <Link to ="/cart" className="cart__widget">
            <AiOutlineShoppingCart/>
            <div className={`cart__count ${cart.length === 0 ? "cart__hidden" : ""}`}>{sumarCantidades()}</div>
        </Link>
    );
}