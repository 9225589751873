import React from 'react'
import { BsFillCheckCircleFill } from "react-icons/bs"

export const AddWidget = () => {
    return (
        <div className='content-add'>
            <BsFillCheckCircleFill className='content-addIcon'/>
            <p className='content-addText'>Producto agregado</p>
        </div>
    )
}
