import ReactWhatsapp from 'react-whatsapp';
import { BsWhatsapp } from "react-icons/bs"

export const Sticky = () => {
    return (
        <ReactWhatsapp  
            className='wpp-content'
            number="+5491166670119" 
            message="Hola equipo de JB Premium, quisiera consultarte por"
        >
            <div className='wpp-btn'>
                <BsWhatsapp className='wpp-icon'/>
            </div>
        
        </ReactWhatsapp>
    )
}
