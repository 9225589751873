import { ConfigBtns } from "../ItemDetail/ConfigBtns"

export const ItemCount = ( {max, min = 0, counter, setCounter} ) => {

    const handleSumar = () => {
        counter < max && setCounter(counter + 1)
    }

    const handleRestar = () => {
        counter > min && setCounter(counter - 1)
    }

    const {configRestar, configSumar} = ConfigBtns(counter, max, min, handleRestar, handleSumar)

    return (
        <>
            <div className="content-count">
                <span className="content-countText">CANTIDAD</span>
                <button className="btn-count" {...configRestar}>-</button>
                <div className="count"><span>{counter}</span></div>
                <button className="btn-count" {...configSumar}>+</button>
            </div>
        </>
    )
}