import { createContext, useState } from "react";

export const CartContext = createContext()



export const CartProvider = ({children}) => {

    const [cart, setCart] = useState([]);
    const [abrirModal, setAbrirModal] = useState(false)
    const [prodAdd, setProdAdd] = useState(false)
    const [advice, setAdvice] = useState(false)
    const [orderId, setOrderId] = useState()
    const [link, setLink] = useState()
    const [values, setValues] = useState({
        nombreIngresado: '',
        apellidoIngresado: '',
        nombreEmpresaIngresado: '',
        emailIngresado: '',
        telefonoIngresado: '',
        direccionIngresada: '',
        localidadIngresada: '',
        provinciaIngresada: '',
        codigoPostalIngresado: '',
        compradorMayorDeEdad: localStorage.getItem("mayorDeEdad")

    })

    const agregarAlCarrito = (item) => {
        //localStorage.setItem("carrito", JSON.stringify([...cart, item]))
        setCart([...cart, item])
    }

    const isInCart = (id) => { 
        return cart.some((prod) => prod.id === id)
    }

    const sumarCantidades = () => {
        return cart.reduce((acumulador, prod) => acumulador + prod.cantidad, 0)
    }

    const totalCompra = () => {
         return cart.reduce((acumulador, prod) => acumulador + prod.cantidad * prod.precioActualizado, 0)
    }

    const eliminarItem = (id) => {
        setCart (cart.filter((prod) => prod.id !== id))
    }
    
    const vaciarCarrito = () => {
        //localStorage.setItem("carrito", JSON.stringify([]))
        setCart([])
    }

    const handleVerImg = () => {
        setAbrirModal(true)
      }

    const handleOcultarImg = () => {
        setAbrirModal(false)
    }

    const handleProdAdd = () => {
        setProdAdd(true)
        setTimeout(() => {
            setProdAdd(false)
        }, 5000);
    }

    const handleAdvice = () => {
        setAdvice(true)
        setTimeout(() => {
            setAdvice(false)
        }, 5000);
    }

    const resetLink = () => {
        setLink()
    }

    return(
        <CartContext.Provider value={{
            cart,
            abrirModal,
            prodAdd,
            advice,
            agregarAlCarrito,
            isInCart,
            sumarCantidades,
            totalCompra,
            vaciarCarrito,
            eliminarItem,
            handleVerImg,
            handleOcultarImg,
            handleProdAdd,
            handleAdvice,
            orderId,
            setOrderId,
            link,
            setLink, 
            values,
            setValues,
            resetLink
        }}>
            {children}
        </CartContext.Provider>
    );
}