import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import img1 from "../../images/pexels-tim-mossholder-2339180.jpg"

export const NotFound = () => {


    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        document.title = "404 Not Found - JB Premium - Vinos Españoles - Distribuidor Oficial"
    }, [])

    return (
        <main>
            <div className='notFound-box'>
                <img className='content-flyerImage' alt="notFound" src={img1}/>
                <div className='notFound-content'>
                    <strong className='notFound-title'>404 NOT FOUND - PAGINA NO ENCONTRADA</strong>
                    <em className='notFound-desc'>Estas en una ruta inexistente. Clickea el boton VOLVER para ir al Inicio</em>
                    <Link to="/" className="card-link">
                        <button className="card-btn">VOLVER</button>
                    </Link>
                </div>
            </div>
        </main>
    )
}
