import { Link } from "react-router-dom"
import img1 from "../../images/pexels-david-1277181.jpg"
import { useEffect } from "react";

export const PaySuccess = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        document.title = "Compra Finalizada - JB Premium - Vinos Españoles - Distribuidor Oficial"
    })

    return (
        <main>
            <div className="pay-expand-success">
                <img className='content-flyerImage' alt="notFound" src={img1}/>
                <div className="pay-content success-color">
                    <p className="pay-title">COMPRA FINALIZADA</p>
                    <div className="pay-box">
                        <p className="pay-desc pay-msj">En breve te llegará un mail con la confirmación, y en breve nos comunicaremos con vos para coordinar la entrega.</p>
                        <p className="pay-desc">Si no recibiste tu correo, recordá revisar tu casilla de SPAM.</p>
                        <p className="pay-desc">Muchas gracias por elegirnos!</p>
                        <Link to="/"><button className="card-btn">VOLVER</button></Link>
                    </div>
                </div>
            </div>
        </main>
    )
}
