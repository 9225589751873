import React from 'react'

export const Menu = ({click, handleClick}) => {

    return (
        <div onClick={handleClick} className={`icon nav-icon-5 ${click ? "open" : ""}`}>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}
