import { Link } from "react-router-dom"
import img1 from "../../images/pexels-david-1277181.jpg"
import { useEffect } from "react";

export const PayPending = () => {

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'auto'});
        document.title = "Pago Pendiente - JB Premium - Vinos Españoles - Distribuidor Oficial"
    })

    return (
        <main>
            <div className="pay-expand-success">
                <img className='content-flyerImage' alt="notFound" src={img1}/>
                <div className="pay-content pending-color">
                    <p className="pay-title">COMPRA FINALIZADA</p>
                    <div className="pay-box">
                        <p className="pay-desc">Mercado Pago nos informa que tu pago está pendiente de acreditación. Pero no te preocupes!</p>
                        <p className="pay-desc">Revisa el estado de tu pago en la aplicación de MP y, si figura acreditado, envianos una captura por Whatsapp.</p>
                        <p className="pay-desc">En breve te llegará un mail con los datos y el estado de tu compra. Recuerda revisar tu SPAM.</p>
                        <p className="pay-desc pay-msj">Luego, nos comunicaremos con vos para verificar el pago y coordinar la entrega.</p>
                        <p className="pay-desc">Muchas gracias por elegirnos!</p>
                        <Link to="/"><button className="card-btn">VOLVER</button></Link>
                    </div>
                </div>
            </div>
        </main>
    )
}
