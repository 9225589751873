import { useContext, useEffect, useState } from "react"
import { Link, Navigate } from "react-router-dom"
import { CardTotal } from "../CardTotal/CardTotal"
import { CartContext } from "../CartContext/CartContext"
import { BsFillQuestionCircleFill } from "react-icons/bs"
import { AiFillExclamationCircle } from "react-icons/ai"
import { Cargando } from "../Cargando/Cargando"
import { Sticky } from "../Sticky/Sticky"

export const Checkout = () => {

    const {cart, totalCompra, handleAdvice, orderId, setOrderId, link, setLink, values, setValues, resetLink} = useContext(CartContext)

    const [loading, setLoading] = useState()

    useEffect(() => {

        if (link) {
            window.scrollTo({top: 0, left: 0, behavior: 'auto'});
            document.title = "Realiza tu pago - JB Premium - Vinos Españoles - Distribuidor Oficial"

        } else {
            window.scrollTo({top: 0, left: 0, behavior: 'auto'});
            document.title = "Detalles de Facturación - JB Premium - Vinos Españoles - Distribuidor Oficial"
        }
        
    }, [link])

    //http://localhost:3000/pago-rechazado?collection_id=null&collection_status=null&payment_id=null&status=null&external_reference=null&payment_type=null&merchant_order_id=null&preference_id=1264300722-467f5469-7860-48cc-ba67-c62087bb13e5&site_id=MLA&processing_mode=aggregator&merchant_account_id=null

    //RESPUESTA CADA VEZ QUE APRIENTAN VOLVER AL SITIO SIN HABER EJECUTADO EL PAGO
    

    const generadorLinks = async () => {

        setLoading(true)

        const data = {
            comprador: { ...values },
            carrito: { ...cart },
            total: totalCompra(),
            fecha: new Date().toLocaleString("es-AR"),
        };

        await fetch('https://us-central1-jb-premium.cloudfunctions.net/app/crear-orden', {
            method: "POST",
            //mode: "cors",
            body: new URLSearchParams({ oc: JSON.stringify(data) }),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded; charset=UTF-8",
                'access-control-allow-origin': 'https://us-central1-jb-premium.cloudfunctions.net/app/crear-orden',
                //"Content-Type": "application/json",
            },
        })
        .then(res => res.json())
        .then(data => {
            setOrderId(data.compraId)
            setLink(data.mp);
        })
        .catch(err => console.log(err))
        .finally(() => {
            setLoading(false)
        })
    }
    
    if (cart.length === 0 || totalCompra() === 0) {
        return <Navigate to="/"/>
    }
    
    const handleInputChange = (e) => {
        setValues({
            ...values,
            [e.target.name]: e.target.value
        })
    }
    
    const handleSubmit = async (e) => {
        e.preventDefault()
        
        if (values.nombreIngresado.length < 3) {
            handleAdvice()
            return
        }
        if (values.apellidoIngresado.length < 2) {
            handleAdvice()
            return
        }
        if (values.emailIngresado.length < 4) {
            handleAdvice()
            return
        }
        if (values.telefonoIngresado.length < 10) {
            handleAdvice()
            return
        }
        if (values.direccionIngresada.length < 5) {
            handleAdvice()
            return
        }
        if (values.localidadIngresada.length < 3) {
            handleAdvice()
            return
        }
        if (values.codigoPostalIngresado.length < 3) {
            handleAdvice()
            return
        }

        generadorLinks()
    }

    return (
        <>
            <section className="main main-checkout">
                <p className="main-title">DETALLES DE FACTURACIÓN Y ENVÍO</p>
                <hr/>
                <p className="form-asterisk">* <em className="form-text">Datos obligatorios</em></p>
                <div className="form">
                    <form className="form-checkout">
                        <div className="form-section">
                                <em className="form-title">Datos Personales</em>
                            <div className="form-section-row">
                                <div className="form-section-col">
                                    <label className="form-text">Nombre <i className="form-asterisk">*</i></label>
                                    <input
                                        className="form-input" 
                                        type="text" 
                                        placeholder="Ingrese su nombre" 
                                        value={values.nombreIngresado} 
                                        onChange={handleInputChange} 
                                        name="nombreIngresado"
                                    />
                                </div>
                                <div className="form-section-col">
                                    <label className="form-text">Apellido <i className="form-asterisk">*</i></label>
                                    <input
                                        className="form-input" 
                                        type="text" 
                                        placeholder="Ingrese su apellido" 
                                        value={values.apellidoIngresado} 
                                        onChange={handleInputChange} 
                                        name="apellidoIngresado"
                                    />
                                </div>
                            </div>
                            <label className="form-text">Empresa<i>(Opcional)</i></label>
                            <input
                                className="form-input" 
                                type="email" 
                                placeholder="Ingrese nombre de la Empresa" 
                                value={values.nombreEmpresaIngresado} 
                                onChange={handleInputChange} 
                                name="nombreEmpresaIngresado"
                            />
                            <label className="form-text">Email <i className="form-asterisk">*</i></label>
                            <input
                                className="form-input" 
                                type="email" 
                                placeholder="Ingrese su email" 
                                value={values.emailIngresado} 
                                onChange={handleInputChange} 
                                name="emailIngresado"
                            />
                            <label className="form-text">Telefono <i className="form-asterisk">*</i></label>
                            <input
                                className="form-input" 
                                type="tel" 
                                placeholder="Ingrese su telefono" 
                                value={values.telefonoIngresado} 
                                onChange={handleInputChange} 
                                name="telefonoIngresado"
                            />
                        </div>
                        <div className="form-section">
                            <em className="form-title">Datos para el envío</em>
                            <div className="form-section-row">
                                <div className="form-section-col">
                                    <label className="form-text">Dirección <i className="form-asterisk">*</i></label>
                                    <input
                                        className="form-input" 
                                        type="text" 
                                        placeholder="Ingrese calle y numero" 
                                        value={values.direccionIngresada} 
                                        onChange={handleInputChange} 
                                        name="direccionIngresada"
                                    />
                                </div>
                                <div className="form-section-col">
                                    <label className="form-text">Localidad <i className="form-asterisk">*</i></label>
                                    <input
                                        className="form-input" 
                                        type="text" 
                                        placeholder="Ingrese su localidad" 
                                        value={values.localidadIngresada} 
                                        onChange={handleInputChange} 
                                        name="localidadIngresada"
                                    />
                                </div>
                            </div>
                            <label className="form-text">Provincia <i className="form-asterisk">*</i></label>
                            <select
                                className="form-input" 
                                placeholder="Elige una opción" 
                                name="provinciaIngresada" 
                                onChange={handleInputChange}
                            >
                                <option value="El usuario no seleccionó ninguna opción">Elige tu opción</option>
                                <option value="Capital Federal">Capital Federal</option>
                                <option value="Buenos Aires">Buenos Aires</option>
                                <option value="Misiones">Misiones</option>
                                <option value="Cordoba">Cordoba</option>
                                <option value="Entre Rios">Entre Rios</option>
                                <option value="La Pampa">La Pampa</option>
                                <option value="Tierra del Fuego">Tierra del Fuego</option>
                                <option value="Santa Fe">Santa Fe</option>
                                <option value="Chaco">Chaco</option>
                                <option value="Mendoza">Mendoza</option>
                                <option value="Salta">Salta</option>
                                <option value="Tucuman">Tucuman</option>
                                <option value="Jujuy">Jujuy</option>
                                <option value="Corrientes">Corrientes</option>
                                <option value="Rio Negro">Rio Negro</option>
                                <option value="Neuquen">Neuquen</option>
                                <option value="San Juan">San Juan</option>
                                <option value="Chubut">Chubut</option>
                                <option value="San Luis">San Luis</option>
                                <option value="Formosa">Formosa</option>
                                <option value="Formosa">Formosa</option>
                                <option value="Santa Cruz">Santa Cruz</option>
                                <option value="La Rioja">La Rioja</option>
                                <option value="Santiago del Estero">Santiago del Estero</option>
                                <option value="Catamarca">Catamarca</option>
                            </select>
                            <label className="form-text">Codigo Postal <i className="form-asterisk">*</i></label>
                            <input
                                className="form-input" 
                                type="text" 
                                placeholder="Ingrese su codigo postal" 
                                value={values.codigoPostalIngresado} 
                                onChange={handleInputChange} 
                                name="codigoPostalIngresado"
                            />
                        </div>
                        <div className="form-btn">
                            <Link to="/cart"><button className="card-btn">VOLVER</button></Link>
                            <button className="card-btn card-success" onClick={handleSubmit} type='submit'>IR A PAGAR</button>
                        </div>
                    </form>
                    <div className="cart-content-total">
                        <em className="cart-totalTitle cart-width">Resumen de mi compra:</em>
                        <div className="cart-width">
                            {cart.map((el) => <CardTotal {...el}/>)}
                        </div>
                        <div className="cart-total cart-width">
                            <p className="cart-totalPrice">TOTAL: ${totalCompra().toLocaleString("es-AR")}</p>
                        </div>
                        <div className="cart-total-btns">
                            <Link className="btn-link" to="/productos"><button className="card-btn card-success card-size">CONTINUAR COMPRA</button></Link>
                        </div>
                    </div>
                </div>
                <div className="form-info">
                    <div className="form-infoIcon">
                        <AiFillExclamationCircle className="form-icon"/>
                    </div>
                    <div className="form-infoDesc">
                        <em className="form-infoText">El envío es gratuito para todo CABA para compras mayores de AR$20.000. Para mas info <Link className='link-detail' to="/detalle-envio">click aquí <BsFillQuestionCircleFill className="icon-question"/></Link></em>
                        <em className="form-infoText">Los datos cargados en este formulario son de uso exclusivo para el envío de la compra y su facturacion. Los mismos nunca seran compartidos fuera de lo mencionado por JB Premium.</em>
                    </div>
                </div>
                <div className={link ? "checkout-modal showModal" : "checkout-modal"}>
                    <div className="checkout-expand">
                        <p className="main-title">POR ULTIMO REALIZA EL PAGO</p>
                        <div className="checkout-success-msj">
                            <p className="checkout-desc">Tu N° de compra es: {orderId ? orderId : null}</p>
                        </div>
                        <hr className="checkout-divisor"/>
                        <div className="checkout-box">
                            <p className="checkout-boxDesc">Si queres modificar tu orden de compra, haz <Link className="btn-link" onClick={resetLink} to="/cart"><button className="card-btn">CLICK AQUI</button></Link></p>
                            <p className="checkout-boxDesc">Si queres agregar más productos a tu orden de compra, haz <Link className="btn-link" onClick={resetLink} to="/productos"><button className="card-btn">CLICK AQUI</button></Link></p>
                            <p className="checkout-boxDesc">Si tenes duda de cómo realizar tu pago, haz <Link className="btn-link" onClick={resetLink} to="/preguntas-frecuentes"><button className="card-btn">CLICK AQUI</button></Link></p>
                        </div>
                        {
                            link
                            ? <iframe className="checkout-pay" title="pago" src={link.init_point}></iframe>
                            : 
                            null
                        }
                    </div>
                </div>
                {loading
                ? <Cargando/>
                : null}
            </section>
            <Sticky/>
        </>
    )
}