import { collection, getDocs } from 'firebase/firestore'
import React, { useEffect, useState } from 'react'
import { useCotDolar } from '../Api/useCotDolar'
import { Cargando } from '../Cargando/Cargando'
import { baseDeDatos, storage } from '../Firebase/Config'
import { Item } from '../Item/Item'
import { getDownloadURL, listAll, ref } from 'firebase/storage'

export const ItemDest = () => {
    
    const [productos, setProductos] = useState([])
    const [imagenes, setImagenes] = useState([])
    const [loading, setLoading] = useState(false)
    const { dolarOficial} = useCotDolar(0)

    useEffect (() => {

        setLoading(true)

        const productosBaseDeDatos = collection(baseDeDatos, 'productos')

        getDocs(productosBaseDeDatos)
            .then((resp) => {
                setProductos( resp.docs.map( (item) => {
                    return {
                        id: item.id,
                        precioDolar: dolarOficial,
                        ...item.data()
                    }
                }))
                const storageRef = ref(storage, '/');
                listAll(storageRef)
                    .then(async (result) => {
                        const promises = result.items.map(async (item) => {
                            const ubi = await getDownloadURL(item);
                            return { ubi, nam: item.name };
                        });
                        const urls = await Promise.all(promises);
                        setImagenes(urls);
                    })
                    .catch((error) => {
                        console.log("Error porque...", error);
                    });
            })
            .finally(() => {
                setLoading(false)
            })
    }, [dolarOficial])

    const productosConImagenes = productos.map((producto) => {
        const imagen = imagenes.find((imagen) => imagen.nam === producto.dato + '.png');
        return { ...producto, ...imagen };
    });

    const cajaIndividual = productosConImagenes.filter((el) => el.id === "0105VjaVJHmC1SV9BXLi")
    const cajaDoble = productosConImagenes.filter((el) => el.id === "01160yB5ulx8pSNyKXcp")
    
    return (
        <>
            {loading 
            ? <Cargando/> 
            :   <>
                    {cajaIndividual.map((el) => <Item {...el}/>)}
                    {cajaDoble.map((el) => <Item {...el}/>)}
                </>
            }
         </>
        
    )
}
