import { ImTruck } from "react-icons/im"
import { BsFillQuestionCircleFill, BsFillBagCheckFill, BsBoxSeam } from "react-icons/bs"
import { BiMedal } from "react-icons/bi"
import { Link } from "react-router-dom"
//import img1 from "../../images/spain-g47d5a3339_1280.png"

export const FlyerEnvio = () => {

    return (
        <section>
            <div className="flyer">
                <p className="flyer-title">VINOS DE LAS MEJORES BODEGAS DE ESPAÑA</p>
                <div className="flyer-contentImg">
                    {/* <div className="bandera">
                        <div className="bandera-lineaRoja"></div>
                        <div className="bandera-lineaAmarilla"></div>
                        <div className="bandera-lineaRoja"></div>
                    </div> */}
                    <div className="flyer-boxImg">
                        <a href="https://www.marquesderiscal.com/">
                            <img className="flyer-img" src="./img/bodegas/new/marques-riscal-new.png" alt="bodega1"/>
                        </a>
                    </div>
                    <div className="flyer-boxImg">
                        <a href="https://www.martincodax.com/es/">
                            <img className="flyer-img" src="./img/bodegas/logo-martin-codax.png" alt="bodega2"/>
                        </a>
                    </div>
                    <div className="flyer-boxImg">
                        <a href="http://www.principedeviana.com/">
                            <img className="flyer-img" src="./img/bodegas/new/principe-de-viana.png" alt="bodega3"/>
                        </a>
                    </div>
                </div>
            </div>
            <div className="flyer-envio">
                <Link to="/detalle-envio" className="flyer-envioDetail">
                    <div>
                        <em className="flyer-envioDesc">Envíos gratis a CABA a partir de AR$20.000<BsFillQuestionCircleFill className="icon-question"/></em>
                    </div>
                    <div className="flyer-boxIcon">
                        <ImTruck className="flyer-envioIcon"/>
                    </div>
                </Link> 
                <div className="flyer-envioDetail">
                    <div>
                        <em className="flyer-envioDesc">Entregas en 24/72hs dentro de CABA</em>
                    </div>
                    <div className="flyer-boxIcon">
                        <BsFillBagCheckFill className="flyer-envioIcon"/>
                    </div>
                </div> 
                <div className="flyer-envioDetail">
                    <div>
                        <em className="flyer-envioDesc">Productos seguros desde nuestro deposito a tu casa</em>
                    </div>
                    <div className="flyer-boxIcon">
                        <BsBoxSeam className="flyer-envioIcon"/>
                    </div>
                </div> 
                <div className="flyer-envioDetail">
                    <div>
                        <em className="flyer-envioDesc">Vinos de las mejores bodegas de Europa</em>
                    </div>
                    <div className="flyer-boxIcon">
                        <BiMedal className="flyer-envioIcon"/>
                    </div>
                </div> 
            </div>
        </section>
    )
}
