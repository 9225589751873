import React, { useEffect, useState } from 'react';

export const Faq = () => {
  const [openDropdown, setOpenDropdown] = useState(null);

  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'auto'});
    document.title = "Preguntas Frecuentes - JB Premium - Vinos Españoles - Distribuidor Oficial"
  }, [])

  const splitContentIntoParagraphs = (content) => {
    return content.split('\n').map((paragraph, index) => (
      <p key={index} className="dropdown-text">{paragraph}</p>
    ));
  };

  const toggleDropdown = (dropdownId) => {
    setOpenDropdown(openDropdown === dropdownId ? null : dropdownId);
  };

  const dropdowns = [
    { id: 1, title: '¿Cómo realizo mi compra?', content: 'Para realizar la compra de tus productos en nuestra página, debes de entrar a cada producto, seleccionar la cantidad deseada y clickear en el boton "AGREGAR AL CARRITO". Una vez agregado el total de los productos, dirigite a tu carrito, y verificá si estan los productos que deseas. Si todo está bien, podes clickear "FINALIZAR COMPRA"; eso te abrirá un formulario para completar los datos personales y dirección de envío de los productos. \nPor último, dentro del formulario encontrarás un botón que dice "IR A PAGAR". Este botón te abrirá un pop-up de Mercado Pago en donde vas a poder realizar el pago con tarjeta de credito o debito.'},
    { id: 2, title: '¿Cómo agrego más vinos ó modifico lo agregado a mi compra?', content: 'Si todavía no generaste el pop-up de Mercado Pago, podés seleccionar el botón "PRODUCTOS" y agregar los productos que desees; o si queres modificar tu orden de compra, haciendo click en el boton "MI CARRITO", vas a poder eliminar algún producto. \nPero si ya completaste los datos en el formulario y generaste el pop-up de Mercado Pago, arriba de la ventana generada vas a tener tres botones donde, el primero te permite modificar la orden de compra, el segundo agregar más productos, y el tercero, entrar a nuestro apartado de preguntas frecuentes.' },
    { id: 3, title: '¿Cómo sé el estado de mi pago?', content: 'Cuando ingreses los datos de tu tarjeta y los datos que Mercado Pago te solicita para el pago, selecciones las coutas, y selecciones el boton "PAGAR", en principio Mercado Pago te informará el estado del mismo. Éste puede ser "APROBADO", "PENDIENTE", o "RECHAZADO". \nLuego, tendrás que apretar el boton "VOLVER AL SITIO", y te confirmaremos el estado de tu pago.' },
    { id: 4, title: '¿Qué sucede si mi pago es aprobado?', content: 'Una vez clickees el boton "VOLVER AL SITIO", verás que éste te llevará a una página de JB Premium donde te detallaremos el estado del pago. \nAdemás, te llegará un correo electrónico a la dirección que nos brindaste, donde te detallaremos todos los datos cargados, y el detalle de tu compra donde te informaremos el N° de orden de compra generado por JB Premium, el N° de operación de Mercado Pago, el detalle de los vinos comprados, más el total y fecha de la compra.' },
    { id: 5, title: '¿Qué sucede si mi pago es rechazado?', content: 'Si tu pago es rechazado, el pop-up de Mercado Pago donde cargarás tus datos para realizar el pago, te mostrará un detalle de porqué se rechazó el pago. Además, recibirás un correo de nuestra parte informando el motivo de rechazo con datos relevantes como el N° de operación de Mercado Pago donde se rechazó la transacción. \nEste dato lo podes buscar en los movimientos de Mercado Pago (desde la aplicación de celular o desde el navegador) y verificar el estado por fuera de nuestra web. De todos modos, pueden volver a intentar con otro medio de pago dentro del mimso pop-up.' },
    { id: 6, title: '¿Qué sucede si mi pago es pendiente?', content: 'Si tu pago es pendiente,significa que Mercado Pago o el banco emisor de la tarjeta que utilizaste, necesita verificar la transacción que se quiere realizar. Esto funciona así para evitar estafas o para que tu tarjeta no sea utilizada por terceros. \nPero no te preocupes; recibirás un mail de nuestra parte y de Mercado Pago informando el estado de tu pago. De todos modos, estas transacciones cambian de estado a "APROBADO" o "RECHAZADO", y dependiendo la resolución de la verificación, esto puede tardar aproximadamente 2 horas. \nAunque si tuvieras alguna duda o consulta con respecto a esto, no dudes en contactarnos por nuestro correo contacto@jbpremium.com.ar o por WhatsApp.' },
    { id: 7, title: '¿Es seguro cargar mis datos personales para hacer la compra?', content: 'Desde JB Premium te aseguramos que tus datos personales son únicamente para tener un registro de las ordenes de compra recibidas, verificar la identidad del comprador, y para poder hacer el envío de los productos comprados. \nCon respecto a los datos para poder realizar el pago, son datos que se cargan en el pop-up de Mercado Pago y no a nosotros. Eso quiere decir que JB Premium no almacena ese tipo de información. \nDe todos modos, es completamente seguro abonar a través de Mercado Pago. Inclusive antes de abonar, podés iniciar sesión con tu cuenta para evitar cargar todos los datos como DNI, N° de tarjeta y solo colocar el codigo de seguridad, las cuotas elegidas, y abonar.' },
    { id: 8, title: '¿Porqué no recibi el correo de JB Premium informando el estado de mi pago?', content: 'Si no recibiste tu correo de parte de JB Premium informando el estado de tu pago, te recomendamos revisar tu correo SPAM. \nDependiendo de la configuración de correo de nuestros clientes, a veces nuestros correos llegan a la carpeta de SPAM y no a la principal.' },
  ];

  return (
    <>
      <section className='main'>
        <p className="main-title">PREGUNTAS FRECUENTES</p>
        <hr/>
        <div>
          {dropdowns.map((dropdown) => (
            <div className="dropdown" key={dropdown.id}>
              <div className="dropdown-header" onClick={() => toggleDropdown(dropdown.id)}>
                <h3 className='dropdown-title'>{dropdown.title}</h3>
                <span>{openDropdown === dropdown.id ? '▲' : '▼'}</span>
              </div>
              <div className={`dropdown-content ${openDropdown === dropdown.id ? 'show' : ''}`}>
                {splitContentIntoParagraphs(dropdown.content)}
              </div>
            </div>
          ))}
        </div>
      </section>
    </>
  );
};
