import { Link } from "react-router-dom"


export const Item = ( {detalle, id, nombre, precio, foto, bodega, tipo, cosecha, precioDolar} ) => {

    return (
        <div className="card">
            <div className="card-imageContent">
                <img className="card-img" src={`/${foto}`} alt={nombre} />
            </div>
            <div className="card-text">
                <strong className="card-title">{nombre}</strong>
                {bodega
                ? <em className="card-desc"><strong>Bodega: </strong>{bodega}</em>
                : null}
                {detalle 
                ? <em className="card-desc">{detalle}</em>
                : null}
                {tipo
                ? <em className="card-desc"><strong>Tipo de Uva: </strong>{tipo}</em>
                : null}
                {cosecha
                ? <em className="card-desc"><strong>Cosecha: </strong>{cosecha}</em>
                : null}
                {
                    precio !== 0
                    ?
                    <>
                        <p className="card-price">U$S {(precio).toLocaleString("es-AR")} BNA / AR$ {(precio * precioDolar).toLocaleString("es-AR")} c/u</p>
                        <Link to={`/detalle/${id}`}>
                            <button className="card-btn">VER MAS</button>
                        </Link>
                    </>
                    :
                    <>
                        <p className="card-price">Disponible Proximamente</p>
                        <Link to={`/detalle/${id}`}>
                            <button className="card-btn">VER MAS</button>
                        </Link>
                    </>
                }
            </div>
        </div>
    )
}